import { t } from "@lingui/macro";
import { SealCheck } from "@phosphor-icons/react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useToast } from "@/client/hooks/use-toast";
import { queryClient } from "@/client/libs/query-client";
import { useVerifyMetysseToken } from "@/client/services/auth/metysse-auth-token/verify-metysse-token";

export const MetysseAuthToken = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get("at");
  const redirect = searchParams.get("redirect");

  const { verifyMetysseToken, loading } = useVerifyMetysseToken();

  useEffect(() => {
    const handleVerifyMetysseToken = async (token: string) => {
      await verifyMetysseToken({ token: token });
      await queryClient.invalidateQueries({ queryKey: ["user"] });

      toast({
        variant: "success",
        icon: <SealCheck size={16} weight="bold" />,
        title: t`Your now connected to Metysse Resume`,
      });

      if (redirect) {
        navigate(redirect, { replace: true });
      } else {
        navigate("/dashboard/resumes", { replace: true });
      }
    };

    if (!accessToken) return;

    void handleVerifyMetysseToken(accessToken);
  }, [accessToken, navigate, verifyMetysseToken]);

  return <div></div>;
};
