import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Helmet } from "react-helmet-async";

import { HeroSection } from "@/client/pages/home/sections/hero";

export const HomePage = () => {
  const { i18n } = useLingui();

  return (
    <main className="relative isolate bg-background">
      <Helmet prioritizeSeoTags>
        <html lang={i18n.locale} />

        <title>
          {t`Metysse Resume`} - {t`A free and open-source resume builder`}
        </title>

        {/*<meta*/}
        {/*  name="description"*/}
        {/*  content="A free and open-source resume builder that simplifies the process of creating, updating, and sharing your resume."*/}
        {/*/>*/}
      </Helmet>

      <HeroSection />
      {/*<LogoCloudSection />*/}
      {/*<StatisticsSection />*/}
      {/*<FeaturesSection />*/}
      {/*<TemplatesSection />*/}
      {/*<TestimonialsSection />*/}
      {/*<SupportSection />*/}
      {/*<FAQSection />*/}
      {/*<ContributorsSection />*/}
    </main>
  );
};
