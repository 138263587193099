import { MessageDto } from "@reactive-resume/dto";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { axios } from "@/client/libs/axios";

export const verifyMetysseToken = async (data: { token: string }) => {
  // eslint-disable-next-line lingui/no-unlocalized-strings
  const authorization = `Bearer ${data.token}`;
  const response = await axios.get<MessageDto, AxiosResponse<MessageDto>>(`/auth/metysse-auth`, {
    headers: {
      Authorization: authorization,
    },
  });

  return response.data;
};

export const useVerifyMetysseToken = () => {
  const {
    error,
    isPending: loading,
    mutateAsync: verifyMetysseTokenFn,
  } = useMutation({
    mutationFn: verifyMetysseToken,
  });

  return { verifyMetysseToken: verifyMetysseTokenFn, loading, error };
};
