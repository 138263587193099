import { createZodDto } from "nestjs-zod/dto";
import { z } from "nestjs-zod/z";

import { jsonResumeSchema } from "./json-resume";

export const postJsonResume = z.object({
  userId: z.string(),
  jsonResume: jsonResumeSchema,
});

export class PostJsonResume extends createZodDto(postJsonResume) {}
